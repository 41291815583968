import { Button, Card, Image, Layout, Segmented, Spin, Tag, Tooltip, Watermark } from "antd";
import ComponentState from "./components/states";
import { Content } from "antd/es/layout/layout";
import ComponentHeader from "./components/header";
import ComponentFooter from "./components/footer";
import React, { useEffect, useState } from "react";
import { Color, Currency, Defaults, QueryId, WebRoute } from "../handlers/enums";
import { useTranslation } from "react-i18next";
import { GetData } from "../handlers/axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MinusCircleOutlined, CloudFilled, CalendarFilled, LinkedinFilled, PlusCircleOutlined, FlagFilled, UserOutlined, SpotifyFilled, QuestionCircleOutlined, ShareAltOutlined, CheckOutlined, InstagramFilled, FacebookFilled, TikTokFilled, WhatsAppOutlined, MailFilled } from "@ant-design/icons";
import SnapchatIcon from "../assets/sprites/Snapchat.svg";
import SoundcloudInverted from "../assets/sprites/SoundcloudInverted.svg";
import Paragraph from "antd/es/typography/Paragraph";
import moment from "moment";
import { Avatar, List } from "antd";
import { useAtom } from "jotai";
import { EventId, TicketSelection } from "../handlers/globals";
import BackupBannerPicture from "../assets/sprites/BackupBannerPicture.jpg";
import { SortOnDigits } from "../handlers/extensions";

export default function Event() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const { i18n, t } = useTranslation();

  const [params,] = useSearchParams();

  const response = GetData(QueryId.SelectEvent, { EventId: params.get("id") });

  const [uniquePlaydays, setUniquePlaydays] = useState([]);

  const [tickets, setTickets] = useState([]);

  const [total, setTotal] = useState(0.00);

  const [amountSelected, setAmountSelected] = useState(0);

  const [, setEventId] = useAtom(EventId);

  const [, setTicketSelection] = useAtom(TicketSelection);

  const [selectedPlayDay, setSelectedPlayDay] = useState(1);

  useEffect(() => {
    setEventId(response?.data?.event?.id);
    setUniquePlaydays([...new Set(response?.data?.stages ? response?.data?.stages.map(x => { return !x.artists ? null : x.artists?.map(y => { return { playDay: y?.playDay } }) }).flat().map(x => { return x?.playDay }).sort(SortOnDigits) : [])]);
    setSelectedPlayDay([...new Set(response?.data?.stages ? response?.data?.stages.map(x => { return !x.artists ? null : x.artists?.map(y => { return { playDay: y?.playDay } }) }).flat().map(x => { return x?.playDay }).sort(SortOnDigits) : [])][0]);
    setTicketSelection([]);
    setTickets(response?.data?.tickets ? response.data.tickets.filter(x => { return moment().isSameOrAfter(x.purchaseStartDate); }).sort((a, b) => { return SortOnDigits(a.sortIndex, b.sortIndex); }) : []);
  }, [response?.data, setTicketSelection, setEventId]);

  const navigate = useNavigate();

  return (
    <>
      <Layout className="!bg-transparent">
        <ComponentHeader lite />
        <Layout className="flex !bg-transparent justify-between lg:justify-center">
          <Content className="min-h-[80vh] pb-8">
            {response.isLoading ? <Spin className="mx-auto w-full mt-5" size="large" />
              : response.isFalse ? <ComponentState error /> : response.isEmpty ? <ComponentState empty /> :
                <>
                  {response.data.event.bannerPicture
                    ? <Image src={response.data.event.bannerPicture} rootClassName="w-full" preview={false} />
                    : <Watermark font={{ color: "#ffffff" }} gap={[0, 250]} rotate={0} offset={[-50, 10]} content={t("preview_banner_picture")}>
                      <Image src={BackupBannerPicture} rootClassName="w-full" preview={false} />
                    </Watermark>
                  }
                  {response.data?.event?.isPublished
                    ? <></>
                    : <div className="bg-sonar-yellow font-semibold p-2 text-center text-lg">{t("preview_mode")}</div>
                  }
                  <div className="mx-8 my-4">
                    <div className="flex justify-between mb-4">
                      <div className="font-semibold text-3xl mb-4">
                        {response.data.event.name}
                        <Paragraph className="!my-auto ml-2 inline-block" copyable={{ icon: [<ShareAltOutlined className="text-2xl" />, <CheckOutlined className="text-2xl" />], tooltips: [t("share"), t("copied")], text: `https://tickets.sonarevent.com/event?id=${response.data.event.id}` }}></Paragraph>
                      </div>
                      <div className="flex gap-2 flex-wrap ml-4">
                        {!response.data.event.facebookUrl ? <>
                        </> :
                          <a href={response.data.event.facebookUrl} rel="noreferrer noopener" target="_blank">
                            <FacebookFilled className="text-3xl" />
                          </a>
                        }
                        {!response.data.event.instagramUrl ? <>
                        </> :
                          <a href={response.data.event.instagramUrl} rel="noreferrer noopener" target="_blank">
                            <InstagramFilled className="text-3xl" />
                          </a>
                        }
                        {!response.data.event.linkedInUrl ? <>
                        </> :
                          <a href={response.data.event.linkedInUrl} rel="noreferrer noopener" target="_blank">
                            <LinkedinFilled className="text-3xl" />
                          </a>
                        }
                        {!response.data.event.snapchatUrl ? <>
                        </> :
                          <a className="select-none" href={response.data.event.snapchatUrl} rel="noreferrer noopener" target="_blank">
                            <Image rootClassName="ant-image-img-hover-blue mt-[2px]" width={28} height={28} src={SnapchatIcon} preview={false} />
                          </a>
                        }
                        {!response.data.event.tiktokUrl ? <>
                        </> :
                          <a href={response.data.event.tiktokUrl} rel="noreferrer noopener" target="_blank">
                            <TikTokFilled className="text-3xl" />
                          </a>
                        }
                      </div>
                    </div>
                    <div className="flex">
                      <div className="mr-1">{t("custom_organisedby")} <span className="font-semibold">{response.data.business?.name}</span></div>
                      <a rel="noreferrer noopener" target="_blank" href={`mailto:${response.data.business.email}`}>
                        <MailFilled className="text-lg" />
                      </a>
                    </div>
                    <div className={`mt-2 ${response.data.sponsers ? "mb-4" : Defaults.StringEmpty}`}>{response.data.event.description}</div>
                    <div className="flex gap-5 flex-wrap justify-around">
                      {response.data.sponsers?.map((sponser) => { return <Image key={sponser.id} className="rounded-lg" preview={false} width={100} height={100} src={sponser.url} /> })}
                    </div>
                    <hr className="my-5" />
                    <div className="flex justify-between gap-5 flex-wrap">
                      <div>
                        <div className="font-semibold text-lg mb-3">{t("information")}</div>
                        <div className="mb-2 whitespace-nowrap">
                          <CalendarFilled className="text-lg mr-2" />
                          {!response.data.event.eventStartDate || !response.data.event.eventEndDate
                            ? t("invalid_date")
                            : moment(response.data.event.eventStartDate).format("MM") === moment(response.data.event.eventEndDate).format("MM")
                              ? moment(response.data.event.eventStartDate).format("DD") === moment(response.data.event.eventEndDate).format("DD")
                                ? moment(response.data.event.eventEndDate).locale(i18n.language).format("DD MMMM")
                                : `${moment(response.data.event.eventStartDate).locale(i18n.language).format("DD")} - ${moment(response.data.event.eventEndDate).locale(i18n.language).format("DD MMMM")}`
                              : `${moment(response.data.event.eventStartDate).locale(i18n.language).format("DD MMMM")} - ${moment(response.data.event.eventEndDate).locale(i18n.language).format("DD MMMM")}`
                          }
                        </div>
                        <div className="whitespace-nowrap">
                          <FlagFilled className="text-lg mr-2" />
                          {!response.data.event.country || !response.data.event.city || !response.data.event.postalCode || !response.data.event.address
                            ? t("invalid_location")
                            : <a rel="noreferrer noopener" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${response.data.event.country}, ${response.data.event.city} ${response.data.event.postalCode}, ${response.data.event.address}`)}`}>{response.data.event.city} {response.data.event.postalCode}, {response.data.event.address}</a>
                          }
                        </div>
                      </div>
                      <div>
                        <div className="font-semibold text-lg mb-3">{t("contact")}</div>
                        <div className="flex flex-col w-min">
                          {!response.data.event.phone ? null :
                            <a className="flex mb-2 whitespace-nowrap" rel="noreferrer noopener" target="_blank" href={`tel:${response.data.event.phone}`}>
                              <WhatsAppOutlined className="text-lg mr-2" />{response.data.event.phone ?? t("invalid_phone")}
                            </a>
                          }
                          <a className="flex whitespace-nowrap" rel="noreferrer noopener" target="_blank" href={`mailto:${response.data.event.email}`}>
                            <MailFilled className="text-lg mr-2" />{response.data.event.email ?? t("invalid_email")}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap flex-grow gap-5 mt-10">
                      {!uniquePlaydays || uniquePlaydays.length <= 0 ? <></> : response.data?.stages?.sort((a, b) => { return SortOnDigits(a.sortIndex, b.sortIndex); }).map((stage) => {
                        return <StageTimeTable key={stage.id} stage={stage} uniquePlaydays={uniquePlaydays} selectedPlayDay={selectedPlayDay} setSelectedPlayDay={setSelectedPlayDay} />
                      })}
                    </div>
                    {!tickets || tickets.length <= 0 ? <></> : <>
                      <div className="font-semibold text-lg mt-5 mb-2">{t("tickets")}</div>
                      {tickets.map((ticket) => { return <Ticket key={ticket.id} isPublished={response.data?.event?.isPublished} demo={response.data?.event?.isDemo} setAmountSelected={setAmountSelected} amountSelected={amountSelected} total={total} setTotal={setTotal} ticket={ticket} currency={response.data?.event?.currency} fee={response.data?.event?.fee} /> })}
                    </>}
                  </div>
                  {!tickets || tickets.length <= 0 ? <></> : <div className={`${amountSelected > 0 ? "sticky border-t" : "mx-8 rounded-md"} bottom-0 py-3 flex-nowrap flex justify-center shadow-sm bg-slate-400`}>
                    <span className="font-semibold my-auto mr-5 whitespace-nowrap">{t("prefix_amount_selected")} {amountSelected} {amountSelected === 1 ? t("suffix_amount_selected") : t("suffix_amount_selected_multiple")} | {response.data?.event?.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} {Math.abs(total).toFixed(2)}</span>
                    {amountSelected <= 0 ? <></> : <Button onSubmit={(event) => { event.preventDefault(); }} onClick={() => { navigate(WebRoute.EventPayment); }} className="bg-sonar-blue font-semibold rounded-full border-none pb-[5px]">{t("continue")}</Button>}
                  </div>
                  }
                  {/* <div className="mx-8 my-4">
                  <hr />
                  <div>MORE EVENTS</div>
                  <div>TILES</div>
                  <hr />
                  <div>OTHER EVENTS</div>
                  <div>TILES</div>
                </div> */}
                </>
            }
          </Content>
        </Layout>
        <ComponentFooter />
      </Layout>
    </>
  );
}

function StageTimeTable(props) {
  const { t } = useTranslation();

  return (<Card key={props.stage.id} title={!props.stage.description ? null :
    <Tooltip placement="bottom" title={props.stage.description}>
      <div className="flex justify-between">
        <span className="text-sonar-white">{props.stage.name}</span>
        <QuestionCircleOutlined className="text-sonar-white" />
      </div>
    </Tooltip>} className="card-p-none bg-slate-600 ant-card-title-stage rounded-t-3xl">
    {props.uniquePlaydays?.length <= 1 ? <></> :
      <Segmented
        className="rounded-none p-0 w-full !text-sonar-blue font-semibold"
        options={props.uniquePlaydays?.map(x => { return { label: `${t("day")} ${x}`, value: x } })}
        onChange={(value) => { props.setSelectedPlayDay(value); }}
        value={props.selectedPlayDay}
      />
    }
    <List
      className="overflow-scroll overflow-x-hidden max-h-80 override-scrollbar pr-5 mt-2 ml-4 min-w-60 custom-ant-list-empty-text"
      dataSource={props.stage.artists?.filter(x => { return x.playDay === props.selectedPlayDay })}
      locale={{ emptyText: <p className="text-sonar-white">{t("prefix_artist_empty")} <b>{t("day")} {props.selectedPlayDay}</b></p> }}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <div className="flex justify-between w-full flex-wrap">
            <div className="flex mr-5 mb-1">
              {item.picture && item.isRevealed
                ? <Avatar size="large" rootClassName="min-w-max" className="w-10 h-10 mr-2" src={item.picture} />
                : <Avatar size="large" rootClassName="min-w-max" className="w-10 h-10 mr-2 bg-slate-400" icon={<UserOutlined className="w-10 h-10 flex justify-center" />} />
              }
              <div className="text-sonar-white min-w-24">
                {item.isRevealed
                  ? <span>{item.name}</span>
                  : <Tooltip title={t("tooltip_tba_event")}>
                    <div className="whitespace-nowrap">
                      <span className="text-nowrap mr-1">{t("tba_long")}</span>
                      <QuestionCircleOutlined />
                    </div>
                  </Tooltip>}
                <div className="flex flex-nowrap mt-1">
                  {!item.spotifyUrl ? <></> :
                    <a href={item.spotifyUrl} rel="noreferrer noopener" target="_blank">
                      <SpotifyFilled className="text-lg mr-2" />
                    </a>
                  }
                  {!item.instagramUrl ? <></> :
                    <a href={item.instagramUrl} rel="noreferrer noopener" target="_blank">
                      <InstagramFilled className="text-lg mr-2" />
                    </a>
                  }
                  {!item.soundcloudUrl ? <></> :
                    <a className="select-none mr-2" href={item.soundcloudUrl} rel="noreferrer noopener" target="_blank">
                      <Image rootClassName="ant-image-img-hover-blue mt-[2px]" width={16} height={16} src={SoundcloudInverted} preview={false} />
                    </a>
                  }
                  {!item.websiteUrl ? <></> :
                    <a href={item.websiteUrl} rel="noreferrer noopener" target="_blank">
                      <CloudFilled className="text-lg mr-2" />
                    </a>
                  }
                </div>
              </div>
            </div>
            <div className="flex flex-nowrap">
              <span className="text-sonar-green">{moment(item.startsAt).format("HH:mm")}</span>
              <span className="text-sonar-white mx-1">-</span>
              <span className="text-sonar-red">{moment(item.endsAt).format("HH:mm")}</span>
            </div>
          </div>
        </List.Item>
      )}
    />
  </Card>);
}

function Ticket(props) {
  const { t } = useTranslation();

  const [amount, setAmount] = useState(0);
  const maxBuy = props.ticket.purchaseLimit - props.ticket.purchasedAmount <= 0
    ? 0
    : !props.ticket.isBulkAllowed
      ? 1
      : props.ticket.purchaseLimit - props.ticket.purchasedAmount >= 10
        ? 10
        : props.ticket.purchaseLimit - props.ticket.purchasedAmount;
  const [ticketSelection, setTicketSelection] = useAtom(TicketSelection);

  return (<div className="flex w-full mb-2">
    <div className="flex w-full">
      <Card bordered={false} className="bg-slate-800 relative rounded-tr-none rounded-br-none pl-3 pr-0 ticket w-full">
        <div className="flex flex-wrap">
          <Tooltip title={props.ticket.description}>
            <div>
              <p className="text-sonar-white">{props.ticket.name}<QuestionCircleOutlined className="text-sonar-white ml-1" />
              </p>
              <p className="mt-1 text-gray-400 text-xs ">{t("custom_incl_sonar_fee_prefix")} {props.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} {props.fee.toFixed(2)} {t("custom_incl_sonar_fee_suffix")}
                {props.ticket.isBulkAllowed && props.ticket.purchaseLimit > 99999
                  ? <></>
                  : props.ticket.purchaseLimit - props.ticket.purchasedAmount <= 0
                    ? <>
                      <br />
                      <span className="whitespace-nowrap">&diams; {t("ticket_timeout")}</span>
                    </>
                    : <>
                      <br />
                      <span className="whitespace-nowrap">&diams; {moment().isAfter(props.ticket.purchaseEndDate) ? t("ticket_timeout") : props.ticket.isBulkAllowed ? props.ticket.purchaseLimit - props.ticket.purchasedAmount - amount <= 50 ? <span>{props.ticket.purchaseLimit - props.ticket.purchasedAmount - amount} {t("suffix_tickets_available")}</span> : <></> : <span>{t("purchase_limit_restriction")}</span>}</span>
                    </>
                }
              </p>
            </div>
          </Tooltip>
          <div className="sale ml-1">
            <div className="flex mb-1">
              {props.ticket.price === 0 ? <Tag color={Color.Green}>{t("free")}</Tag> :
                <p className={`text-sonar-white ml-4 whitespace-nowrap ${(!props.ticket.salePercentage || props.ticket.salePercentage <= 0 || moment().isAfter(props.ticket.purchaseEndDate) ? Defaults.StringEmpty : "strike-through")}`}>{props.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} {(props.ticket.price + props.fee).toFixed(2)}</p>
              }
              {!props.ticket.salePercentage || props.ticket.salePercentage <= 0 || moment().isAfter(props.ticket.purchaseEndDate) ? <></> :
                <p className="text-sonar-red ml-4 whitespace-nowrap">{props.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} {((props.ticket.price - (props.ticket.price / 100 * props.ticket.salePercentage)) + props.fee).toFixed(2)}</p>
              }
            </div>
            {!props.ticket.salePercentage || props.ticket.salePercentage <= 0 || moment().isAfter(props.ticket.purchaseEndDate) ? <></> :
              <Tag color="#ff0000" className="float-right">{t("ticket_sale")}</Tag>
            }
          </div>
        </div>
      </Card>
      <div className="relative">
        <div className="rounded-lg absolute w-3 h-3 bg-white z-10 left-[-6px] top-[-6px]">
        </div>
        <div className="rounded-lg absolute w-3 h-3 bg-white z-10 left-[-6px] bottom-[-6px]">
        </div>
      </div>
    </div>
    <Card bordered={false} className="bg-slate-700 relative rounded-tl-none rounded-bl-none pl-0 pr-3 card-p-sm ticket">
      {props.demo || !props.isPublished
        ? <div className="ml-2 h-full w-max">
          <span className="text-sonar-white flex flex-col justify-center h-full">{props.demo ? t("demo") : t("unpublished")}</span>
        </div>
        : moment().isAfter(props.ticket.purchaseEndDate) || props.ticket.purchaseLimit - props.ticket.purchasedAmount <= 0
          ? <div className="ml-2 h-full w-max">
            <span className="text-sonar-white flex flex-col justify-center h-full">{t("unstocked")}</span>
          </div>
          : <div className="ml-2 h-full w-max max-md:flex max-md:flex-col">
            <Button type="text" disabled={amount <= 0} className="h-full hover-text-green-enabled hover-text-red-disabled" onClick={() => { if (amount - 1 < 0) { setAmount(0); return; } setAmount(amount - 1); ticketSelection.splice(ticketSelection.indexOf(props.ticket), 1); props.setAmountSelected(props.amountSelected - 1); props.setTotal(props.total - (props.ticket.price === 0 ? props.ticket.price : ((props.ticket.price - (props.ticket.price / 100 * props.ticket.salePercentage)) + props.fee))); setTicketSelection(ticketSelection); }} icon={<MinusCircleOutlined className={amount <= 0 ? "text-sonar-red" : "text-sonar-white"} />} />
            <span className="text-sonar-white mx-2 text-center">{amount}</span>
            <Button type="text" disabled={amount >= maxBuy} className="h-full hover-text-green-enabled hover-text-red-disabled" onClick={() => { if (amount + 1 > maxBuy) { setAmount(maxBuy); return; } setAmount(amount + 1); ticketSelection.push(props.ticket); props.setAmountSelected(props.amountSelected + 1); props.setTotal(props.total + (props.ticket.price === 0 ? props.ticket.price : ((props.ticket.price - (props.ticket.price / 100 * props.ticket.salePercentage)) + props.fee))); setTicketSelection(ticketSelection); }} icon={<PlusCircleOutlined className={amount >= maxBuy ? "text-sonar-red" : "text-sonar-white"} />} />
          </div>
      }
    </Card>
  </div>);
}